<script setup lang="ts">
const { locale } = useI18n();
const htmlAttrs = reactive({
  class: "",
});

watch(
  locale,
  () => {
    if (["de", "es", "am"].includes(locale.value)) {
      htmlAttrs.class = "size-0-85";
    } else if (["ru", "fr"].includes(locale.value)) {
      htmlAttrs.class = "size-0-80";
    } else {
      htmlAttrs.class = "";
    }
  },
  {
    immediate: true,
  }
);

const headParams: any = {};
if (process.env.NODE_ENV !== "development") {
  headParams.link = [
    {
      rel: "preload",
      type: "font/woff2",
      href: "/font/v2/InterVariable.woff2",
      as: "font",
      crossorigin: "",
    },
    {
      rel: "preload",
      type: "font/woff2",
      href: "/font/v2/InterVariable-Italic.woff2",
      as: "font",
      crossorigin: "",
    },
  ];
}
useHead({
  ...headParams,
  htmlAttrs: htmlAttrs,
});
</script>

<template>
  <div class="min-h-full">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style>
.layout-enter-active,
.layout-leave-active {
  transition: all 0.4s;
}
.layout-enter-from,
.layout-leave-to {
  filter: grayscale(1);
}
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
