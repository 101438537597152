<script setup lang="ts">
const route = useRoute();
const activeIndex = computed<string>(() => {
  const path = route.path;
  if (path.includes("/trade/")) return "/trade";
  return path;
});
const localePath = useLocalePath();
const { width } = useWindowSize();
const mounted = useMounted();
const drawer = ref(false);
const widthPoint = 1100;
</script>

<template>
  <el-row :gutter="20" class="main-header-row" align="middle">
    <el-col :span="-1">
      <NuxtLink :to="localePath('/')">
        <el-button link type="primary" round>Go to main site</el-button>
      </NuxtLink>
    </el-col>
    <div class="flex-grow"></div>
    <el-col :span="-1" v-if="width > widthPoint && mounted">
      <AdminMenu :activeIndex="activeIndex" mode="horizontal" />
    </el-col>
    <el-col :span="-1">
      <Notifications />
    </el-col>
    <el-col :span="-1" v-if="width <= widthPoint">
      <el-button round @click="drawer = true">Menu</el-button>
    </el-col>
  </el-row>
  <el-drawer v-model="drawer" size="80%" class="menu-drawer">
    <AdminMenu
      :activeIndex="activeIndex"
      mode="vertical"
      @select="drawer = false"
    />
  </el-drawer>
</template>

<style lang="scss" scoped>
.main-header-row {
  min-height: 60px;
}
</style>
