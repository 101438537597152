import { request } from "@/api";
import type { UserProfile } from "@/shared/types";

export const uploadPic = async (model: Record<string, string>) => {
  return request("/user/pic-upload", {
    method: "POST",
    body: model,
  });
};

export const changePassword = async (model: Record<string, string>) => {
  if (model.tfaCode === "") {
    delete model.tfaCode;
  }
  return request("/user/password-change", {
    method: "POST",
    body: model,
  });
};

export const get2faimage = async () => {
  return request<{ data: string }>("/user/2fa-image", {
    method: "POST",
  });
};

export const enable2fa = async (tfaCode: string) => {
  return request("/user/2fa-enable", {
    method: "POST",
    body: {
      tfaCode,
    },
  });
};

export const disable2fa = async (tfaCode: string) => {
  return request("/user/2fa-disable", {
    method: "POST",
    body: { tfaCode },
  });
};

export const getProfile = async (userId: string) => {
  return request<UserProfile>(`/user/${userId}`);
};

export const fetchEmailForPasswordReset = async (email: string) => {
  return request("/user/reset-password-email", {
    method: "POST",
    body: { email },
  });
};

interface ResetPasswordI {
  password: string;
  hash: string;
}

export const fetchPasswordReset = async (body: ResetPasswordI) => {
  return request("/user/reset-password", {
    method: "POST",
    body,
  });
};

export const setLanguage = async (lang: string) => {
  return request("/user/lang", {
    method: "POST",
    body: { lang },
  });
};
