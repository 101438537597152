import { UserRoles } from "@/shared/enums";

export default defineNuxtRouteMiddleware(() => {
  const { data: userData, token, refreshToken } = useAuth();

  if (
    token.value &&
    refreshToken.value &&
    userData.value?.role &&
    userData.value?.role !== UserRoles.Moderator &&
    userData.value?.role !== UserRoles.Admin
  ) {
    return abortNavigation();
  }
});
