import { locales } from "@/shared/consts";

const findLocale = (localePassed: string) => {
  let foundLocale: string | null = null;
  locales.forEach((lang) => {
    if (localePassed.indexOf(`/${lang}/`) !== -1) {
      foundLocale = lang;
    }
  });
  return foundLocale;
};

const getLocaleFromRoute = () => {
  const route = useRoute();
  return findLocale(route.path);
};

export default defineI18nConfig(() => {
  const cookieLang = useCookie("lang");
  const cookieLangRedirected = useCookie("i18n_redirected");
  let locale: string | null | undefined =
    cookieLangRedirected.value || cookieLang.value;
  if (!locale || locale === "") {
    locale = getLocaleFromRoute();
  }
  if (!locale || locale === "") {
    locale = useBrowserLocale();
  }
  locale = locale || "en";
  return {
    locale: locale as any,
    legacy: false,
    fallbackLocale: "en",
  };
});
