import { UserRoles } from "@/shared/enums";

export const usePermission = () => {
  const { data: user } = useAuth();

  const isModerator = computed(() => {
    return user.value?.role === UserRoles.Moderator;
  });
  const isAdmin = computed(() => {
    return user.value?.role === UserRoles.Admin;
  });

  return { isModerator, isAdmin };
};
