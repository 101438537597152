export enum ExchangeDirection {
  FiatToCrypto = "FiatToCrypto",
  CryptoToFiat = "CryptoToFiat",
}

export enum RequestStatus {
  kRequestFailed = "kRequestFailed",
  kRequestCancelled = "kRequestCancelled",
  kUploadCard = "kUploadCard",
  kWaitingALM = "kWaitingALM",
  kWaitingCryptoPayment = "kWaitingCryptoPayment",
  kWaitingModeratorCardCheck = "kWaitingModeratorCardCheck",
  kWaitingFiatPayment = "kWaitingFiatPayment",
  kPreparingCryptoAddress = "kPreparingCryptoAddress",
  kPreparingFiatRequesites = "kPreparingFiatRequesites",
  kSendingFiatPayment = "kSendingFiatPayment",
  kSendingCryptoPayment = "kSendingCryptoPayment",
  kConfirningFiatPayment = "kConfirningFiatPayment",
  kConfirmingCryptoPayment = "kConfirmingCryptoPayment",
  kRequestCompleted = "kRequestCompleted",
}

export enum RequestAction {
  kCancelRequest = "kCancelRequest",
  kUploadCard = "kUploadCard",
  kConfirmCard = "kConfirmCard",
  kRequestCompleted = "kRequestCompleted",
  kSelectFiatRequisites = "kSelectFiatRequisites",
  kConfirmFiatPaymentUser = "kConfirmFiatPaymentUser",
  kConfirmCryptoPaymentUser = "kConfirmCryptoPaymentUser",
  kConfirmFiatPaymentModerator = "kConfirmFiatPaymentModerator",
  kConfirmCryptoPaymentModerator = "kConfirmCryptoPaymentModerator",
  kCompleteRequest = "kCompleteRequest",
}
