import { request } from "@/api";

export const fetchExchangeFrom = async (toFilter?: string | number) => {
  return request<any>("/exchange/from", {
    params: {
      to: toFilter || "",
    },
  });
};

export const fetchExchangeTo = async (fromFilter?: string | number) => {
  return request<any>("/exchange/to", {
    params: {
      from: fromFilter || "",
    },
  });
};

export const fetchCreateExchangeOrder = async (body = {}, ref: string = "") => {
  const params: Record<string, string> = {};
  if (ref && ref !== "") {
    params.ref = ref;
  }
  return request<any>("/exchange/create", {
    method: "POST",
    params,
    body,
  });
};

export const fetchPreviewExchangeOrder = async (params = {}) => {
  return request<any>("/exchange/preview", {
    params,
    method: "GET",
  });
};

export const fetchPreviewExchangeRequests = async (params = {}) => {
  return request<any[]>("/exchange/requests", {
    params,
    method: "GET",
  });
};

export const fetchExchangeOrder = async (
  exchangeId: string,
  params: Record<string, string> = {}
) => {
  return request<any>(`/exchange/${exchangeId}`, {
    params,
    method: "GET",
  });
};

export const fetchChangeExchangeStatus = async (
  exchangeId: string,
  queryPassed: Record<string, any> = {},
  bodyPassed: Record<string, any> = {}
) => {
  const params: Record<string, string> = {
    action: queryPassed.action,
  };
  if (!!queryPassed.tfaCode) {
    params.tfaCode = queryPassed.tfaCode;
  }
  if (!!queryPassed.token) {
    params.token = queryPassed.token;
  }
  if (!!queryPassed.ppoId) {
    params.ppoId = queryPassed.ppoId;
  }
  const body: Record<string, any> = {};
  if (bodyPassed.sendTxid) {
    body.sendTxid = bodyPassed.sendTxid;
  }
  if (bodyPassed.moderatorComment) {
    body.moderatorComment = bodyPassed.moderatorComment;
  }
  if (bodyPassed.selectedAmounts) {
    body.withdrawalPPO = [];
    Object.keys(bodyPassed.selectedAmounts).forEach((key) => {
      body.withdrawalPPO.push({
        id: key,
        amount: bodyPassed.selectedAmounts[key] * 100,
      });
    });
  }
  return request<any>(`/exchange/${exchangeId}/status`, {
    params,
    body,
    method: "POST",
  });
};
