import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/app/middleware/auth.ts"),
  moderator: () => import("/usr/src/app/middleware/moderator.ts"),
  partner: () => import("/usr/src/app/middleware/partner.ts"),
  "sidebase-auth": () => import("/usr/src/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}