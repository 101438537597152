<script lang="ts" setup>
import { ChatDotRound } from "@element-plus/icons-vue";
const { t } = useI18n();
const { width } = useWindowSize();
const route = useRoute();
const localePath = useLocalePath();

const { isAdmin, isModerator } = usePermission();
const adminButton = computed(() => {
  return isAdmin.value || isModerator.value;
});
const showChatButton = computed(() => {
  const restrictedPath = [
    "support-chat",
    "exchange/requests/",
    "trade/orders/",
  ];
  const anyOfRestrictedPath = restrictedPath.some((path) =>
    route.path.includes(path)
  );
  return !anyOfRestrictedPath && !adminButton.value;
});
const counter = useInterval(1000);
const mounted = ref(false);
onMounted(() => {
  mounted.value = true;
});
const showMobileMenu = computed(() => mounted.value && width.value < 588);
</script>

<template>
  <el-container class="min-h-full">
    <el-header class="main-header" height="auto">
      <HeaderMenu />
    </el-header>
    <el-main>
      <slot />
    </el-main>
    <el-footer height="auto" class="main-footer">
      <FooterMain />
    </el-footer>
    <Transition name="fade">
      <FooterMobileMenu v-if="showMobileMenu" />
    </Transition>
    <ClientOnly>
      <div class="support-button-cont" v-if="showChatButton">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="t('p-support-chat-welcome')"
          placement="top-start"
          :auto-close="3000"
        >
          <nuxt-link :to="localePath('/support-chat')">
            <el-button
              size="large"
              type="primary"
              :class="counter % 100 > 98 ? 'jump' : ''"
              circle
            >
              <el-icon><ChatDotRound /></el-icon>
            </el-button>
          </nuxt-link>
        </el-tooltip>
      </div>
    </ClientOnly>
  </el-container>
</template>

<style lang="scss" scoped>
.jump {
  animation: jump-animation 1s linear infinite;
}

@keyframes jump-animation {
  0%,
  29.9%,
  30%,
  59.9%,
  60% {
    transform: translateY(0);
  }
  1%,
  31% {
    transform: translateY(-3px);
  }
}
.support-button-cont {
  z-index: 999;
  position: fixed;
  bottom: 70px;
  right: 10px;
  & .el-button {
    box-shadow: 1px 2px 5px #0009;
  }
  .el-icon {
    font-size: 30px;
  }
}
.main-footer {
  margin-top: 2rem;
  text-align: center;
}
.footer-menu {
  padding: 0;
  &::after {
    content: "";
    clear: both;
    float: none;
    display: block;
  }
  li {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
    a {
      color: var(--el-color-primary);
    }
    @media (max-width: 988px) {
      display: block;
      float: left;
      width: 50%;
      box-sizing: border-box;
    }

    &:nth-child(2n - 1) {
      &::before {
        display: none;
      }
    }
  }
  li + li {
    margin-left: 40px;
    @media (max-width: 988px) {
      margin-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      height: 100%;
      width: 1px;
      background: var(--el-border-color-darker);
      @media (max-width: 988px) {
        left: 0;
      }
    }
  }
}
.main-header {
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 100;
  background: transparent;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: -1;
    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: 1;
    box-shadow: inset 0 10px 20px -10px var(--el-bg-color);
  }
}
</style>
