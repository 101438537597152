<script setup lang="ts">
const emit = defineEmits(["select"]);
const localePath = useLocalePath();
const { isAdmin, isModerator } = usePermission();
const props = defineProps<{
  activeIndex: string;
  mode: "horizontal" | "vertical";
}>();
</script>

<template>
  <el-menu
    :default-active="props.activeIndex"
    :mode="props.mode"
    menu-trigger="click"
    background-color="transparent"
    unique-opened
    close-on-click-outside
    :ellipsis="false"
    router
    @select="emit('select', $event)"
  >
    <el-menu-item :index="localePath('/administration/stats')" v-if="isAdmin">
      Statistic
    </el-menu-item>
    <el-menu-item
      :index="localePath('/administration/users')"
      v-if="isModerator || isAdmin"
    >
      Users
    </el-menu-item>
    <el-menu-item
      :index="localePath('/administration/disputes')"
      v-if="isModerator || isAdmin"
    >
      Disputes P2P
    </el-menu-item>
    <el-sub-menu index="1" v-if="isModerator || isAdmin">
      <template #title>
        <span>Partners</span>
      </template>
      <el-menu-item
        :index="localePath('/administration/partner/setup')"
        v-if="isAdmin"
      >
        Partner setup
      </el-menu-item>
      <el-menu-item
        :index="localePath('/administration/partner/withdrawals')"
        v-if="isModerator || isAdmin"
      >
        Partner withdrawals
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="2" v-if="isModerator || isAdmin">
      <template #title>
        <span>Exchange</span>
      </template>
      <el-menu-item
        :index="localePath('/administration/exchange/setup')"
        v-if="isAdmin"
      >
        Exchange setup
      </el-menu-item>
      <el-menu-item
        :index="localePath('/administration/exchange/requests')"
        v-if="isModerator || isAdmin"
      >
        Exchange requests
      </el-menu-item>
      <el-menu-item
        :index="localePath('/administration/exchange/dashboard')"
        v-if="isModerator || isAdmin"
      >
        Exchange Dashboard
      </el-menu-item>
    </el-sub-menu>
    <el-menu-item
      :index="localePath('/administration/chats')"
      v-if="isModerator || isAdmin"
    >
      Chats
    </el-menu-item>
  </el-menu>
</template>
