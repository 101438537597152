<script setup lang="ts">
import CountryFlag from "vue-country-flag-next";
import { setLanguage } from "@/api";
import { dayjsVocab } from "@/shared/consts";
const { locale, setLocale } = useI18n();
const dayjs = useDayjs();
const lang = useCookie("lang");
const { data: user } = useAuth();

const changeLocale = (locale: string) => {
  setLocale(locale as any);
  dayjs.locale(dayjsVocab[locale] || locale);
  lang.value = locale;
  if (user.value) {
    try {
      setLanguage(locale);
    } catch (error) {
      console.error(error);
    }
  }
};
const getFlagByLocale = computed(() => {
  switch (locale.value) {
    case "en":
      return "gb";
    default:
      return locale.value;
  }
});
const setDefaultLang = () => {
  const userLang = navigator.language || (navigator as any).userLanguage;
  let lang1 = "",
    lang2 = "";
  if (userLang) {
    if (userLang.indexOf("-")) {
      lang1 = userLang.split("-")[0];
      lang2 = userLang.split("-")[1];
    }
    if (Object.keys(dayjsVocab).includes(lang1)) {
      changeLocale(lang1);
    } else if (Object.keys(dayjsVocab).includes(lang2)) {
      changeLocale(lang2);
    }
  }
};
onBeforeMount(() => {
  if (lang.value && lang.value !== "") {
    dayjs.locale(dayjsVocab[lang.value] || lang.value);
  } else {
    setDefaultLang();
  }
});
</script>

<template>
  <div class="locale-change-cont">
    <el-dropdown trigger="click" @command="changeLocale">
      <CountryFlag
        class="cursor-pointer display-block selected-flag"
        :country="getFlagByLocale"
        rounded
        shadow
        size="normal"
      />
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="en">
            <CountryFlag country="gb" size="small" />
            <span class="ml-2">English</span>
          </el-dropdown-item>
          <el-dropdown-item command="de">
            <CountryFlag country="de" size="small" />
            <span class="ml-2">Deutsch</span>
          </el-dropdown-item>
          <el-dropdown-item command="fr">
            <CountryFlag country="fr" size="small" /><span class="ml-2"
              >Français</span
            >
          </el-dropdown-item>
          <el-dropdown-item command="ru">
            <CountryFlag country="ru" size="small" />
            <span class="ml-2">Русский</span>
          </el-dropdown-item>
          <el-dropdown-item command="es">
            <CountryFlag country="es" size="small" />
            <span class="ml-2">Español</span>
          </el-dropdown-item>
          <el-dropdown-item command="cn">
            <CountryFlag country="cn" size="small" />
            <span class="ml-2">汉语</span>
          </el-dropdown-item>
          <el-dropdown-item command="am">
            <CountryFlag country="am" size="small" />
            <span class="ml-2">հայերեն</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<style scoped lang="scss">
.locale-change-cont {
  :deep(.selected-flag) {
    margin: 0;
  }
}
</style>
