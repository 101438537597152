<script lang="ts" setup>
import VueApexCharts from "vue3-apexcharts";
import { fetchHistoryCharts } from "@/api";
import { CaretTop, CaretBottom } from "@element-plus/icons-vue";
import { formatNumberWithSpaces } from "@/shared/utils";
const { width } = useWindowSize();
const UseRatesStore = useRatesStore();
const { rates, loading: ratesLoading } = storeToRefs(UseRatesStore);
const dayjs = useDayjs();
const props = defineProps<{
  fsym: string;
  tsym: string;
  interval: string;
  limit: number;
}>();

const options = reactive({
  tooltip: {
    enabled: false,
  },
  chart: {
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLables: {
    enabled: false,
  },
  yaxis: {
    show: false,
    showAlways: false,
    crosshairs: {
      show: false,
    },
  },
  xaxis: {
    show: false,
    showAlways: false,
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  grid: {
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  stroke: {
    width: 2,
  },
  labels: [] as string[],
});
const series = ref<any[]>([]);
const loading = ref(true);
const percents = ref("");
const fetchNewData = async () => {
  loading.value = true;
  const res = await fetchHistoryCharts({
    fsym: props.fsym,
    tsym: props.tsym,
    interval: props.interval,
    limit: props.limit,
  });

  const labels = res.map((item) =>
    dayjs(item.time * 1000).format("DD.MM.YYYY")
  );
  const data = res.map((item) => item.close);
  options.labels = labels;
  series.value[0] = {
    name: `${props.fsym} / ${props.tsym}` || "no title",
    data,
  };
  percents.value = (
    ((data[data.length - 1] - data[0]) / data[0]) *
    100
  ).toFixed(2);
  loading.value = false;
};
watch(() => props, fetchNewData, { deep: true, immediate: true });
const chartHeight = computed(() => (width.value > 600 ? 150 : 100));
const filtredRate = computed(() => {
  const filtred = Object.entries(rates.value).filter(([key]) =>
    [props.fsym].includes(key)
  );
  return Object.fromEntries(filtred);
});
</script>

<template>
  <div>
    <el-card class="history-chart-item mb-1" v-loading="loading">
      <div class="header">
        <span class="mr-1">{{ props.fsym }} / {{ props.tsym }}</span>
        <span v-if="+percents === 0" class="opacity-55">-</span>
        <span
          v-else
          class="text-nowrap"
          :class="+percents > 0 ? 'color-success' : 'color-danger'"
        >
          <el-icon class="vertical-middle">
            <CaretTop v-if="+percents > 0" />
            <CaretBottom v-else />
          </el-icon>
          {{ +percents > 0 ? `+${percents}` : percents }} %
        </span>
      </div>
      <div class="chart-cont">
        <VueApexCharts
          v-if="!loading"
          type="line"
          :height="chartHeight"
          :options="options"
          :series="series"
        ></VueApexCharts>
      </div>
    </el-card>
    <el-card class="rates-item" v-loading="ratesLoading && filtredRate">
      <h3 class="m-0">{{ fsym }} rates:</h3>
      <p
        class="font-size-4 m-0 text-nowrap"
        :span="12"
        :xs="24"
        v-for="(cur, curKey) in filtredRate[fsym]"
        :key="curKey"
      >
        {{ curKey }}:
        <i>{{ formatNumberWithSpaces(cur) }}</i>
      </p>
    </el-card>
  </div>
</template>

<style lang="scss" scoped>
.history-chart-item {
  position: relative;
  width: 100%;
  :deep(.el-card__body) {
    padding: 0 0 0 0;
  }
}
.rates-item {
  min-height: 50px;
  :deep(.el-card__body) {
    padding: 0.5rem;
  }
}
.chart-cont {
  height: 130px;
  margin-bottom: -5px;
  @media (max-width: 600px) {
    height: 100px;
  }
}
.header {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  line-height: 100%;
  font-size: 1rem;
  @media (max-width: 1100px) {
    font-size: 1rem;
  }
}
</style>
